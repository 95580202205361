import 'core-js/stable';
import $ from 'jquery';
import 'jquery-ui-1-11-4';
import 'jquery-validation';
import 'spectrum-colorpicker';
import 'free-jqgrid';
import _ from 'underscore';
import Backbone from 'backbone';
import 'backbone.radio';
import Marionette from 'backbone.marionette';
import Odometer from 'odometer';
import Hashtable from 'jshashtable';
import proj4 from 'proj4';
// Openlayers
import olSphere from 'ol/sphere';
import olProj from 'ol/proj';
import Projection from 'ol/proj/projection';
import Observable from 'ol/observable';
import VectorSource from 'ol/source/vector';
import BingMapsSource from 'ol/source/bingmaps';
import TileWMSSource from 'ol/source/tilewms';
import ImageStaticSource from 'ol/source/imagestatic';
import GeoJSON from 'ol/format/geojson';
import VectorLayer from 'ol/layer/vector';
import TileLayer from 'ol/layer/tile';
import ImageLayer from 'ol/layer/image';
import DragBox from 'ol/interaction/dragbox';
import Draw from 'ol/interaction/draw';
import condition from 'ol/events/condition';
import Style from 'ol/style/style';
import Fill from 'ol/style/fill';
import Stroke from 'ol/style/stroke';
import CircleStyle from 'ol/style/circle';
import Overlay from 'ol/overlay';
import __ol__ from 'ol/index.js';
import Control from 'ol/control/control';
import Attribution from 'ol/control/attribution';
import Zoom from 'ol/control/zoom';
import ZoomSlider from 'ol/control/zoomslider';
import ZoomToExtent from 'ol/control/zoomtoextent';
import FullScreen from 'ol/control/fullscreen';
import MousePosition from 'ol/control/mouseposition';
import OverviewMap from 'ol/control/overviewmap';
import ScaleLine from 'ol/control/scaleline';
import View from 'ol/view';
import Map from 'ol/map';
import olCoordinate from 'ol/coordinate';
import Polygon from 'ol/geom/polygon';
import LineString from 'ol/geom/linestring';

window.proj4 = proj4;

window.ol = {
	Sphere: olSphere,
	proj: {
		toLonLat: olProj.toLonLat,
		transformExtent: olProj.transformExtent,
		get: olProj.get,
		Projection: Projection,
		addProjection: olProj.addProjection,
		METERS_PER_UNIT: olProj.METERS_PER_UNIT,
		transform: olProj.transform
	},
	Observable: Observable,
	source: {
		Vector: VectorSource,
		BingMaps: BingMapsSource,
		TileWMS: TileWMSSource,
		ImageStatic: ImageStaticSource
	},
	format: {
		GeoJSON: GeoJSON
	},
	layer: {
		Vector: VectorLayer,
		Tile: TileLayer,
		Image: ImageLayer
	},
	interaction: {
		DragBox: DragBox,
		Draw: Draw
	},
	events: {
		condition: condition
	},
	style: {
		Style: Style,
		Fill: Fill,
		Stroke: Stroke,
		Circle: CircleStyle
	},
	Overlay: Overlay,
	inherits: __ol__.inherits,
	control: {
		Control: Control,
		Attribution: Attribution,
		Zoom: Zoom,
		ZoomSlider: ZoomSlider,
		ZoomToExtent: ZoomToExtent,
		FullScreen: FullScreen,
		MousePosition: MousePosition,
		OverviewMap: OverviewMap,
		ScaleLine: ScaleLine
	},
	View: View,
	Map: Map,
	coordinate: olCoordinate,
	geom: {
		Polygon: Polygon,
		LineString: LineString
	}
}

// Dependency of jquery-numberformatter
window.Hashtable = Hashtable

// jQuery and related plugins that require $/jQuery on window
window.$ = window.jQuery = $;
$.migrateMute = true;
require('jquery-migrate');
require('jquery-numberformatter');


// Backbone and dependencies
window._ = _
window.Backbone = Backbone;
window.Marionette = window.Mn = Marionette;

window.Odometer = Odometer;

